import { Injectable } from '@angular/core';
import { concatMap, from, Observable } from 'rxjs';
import { ModalLoginResult } from '../components/modal-login/modal-login.model';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class LoginDialogService {
    constructor(private matDialog: MatDialog) {}

    /**
     *
     *
     * Open login dialog
     *
     *
     *
     * @returns the afterClosed Observable for action to be
     *     executed after the dialog is closed
     */
    public openDialog(): Observable<ModalLoginResult> {
        const modalLoginComponent$ = from(
            import('../components/modal-login/modal-login.component').then(
                (c) => c.ModalLoginComponent
            )
        );

        return modalLoginComponent$.pipe(
            take(1),
            concatMap((modalLoginComponent) => {
                return this.matDialog
                    .open<any, undefined, ModalLoginResult>(
                        modalLoginComponent,
                        {
                            position: {
                                top: '12vh'
                            },
                            disableClose: true,
                            width: '645px',
                            maxHeight: '80vh'
                        }
                    )
                    .afterClosed();
            }),
            take(1)
        );
    }
}
